.content-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: calc(100vw + 10rem);
    width: 100%; 
    margin: 20vh 0;
}



.content-item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5rem;
    margin: 0 15vw;
    background-color: var(--secondary-light-color);
    box-shadow: -1rem -1rem 1.7rem rgba(0, 0, 0, 0.7);
}

@media screen and (min-width: 1600px) {
  .content-pane {
    height: calc(40vw + 10rem);
  }
  .content-item {
    margin: 0 30vw;
  }
}

.content-item h2 {
    color: var(--accent-color);
    text-align: center;
    font-size: 60px;
    padding: 1rem 0;
    height: 8rem;
    transition: all 1s ease-out;
    justify-self: center;
    align-self: center;
}


.content-item .description {
    color: var(--accent-color);
    font-size: 24px;
    padding: 1rem;
    height: 8rem;
    transition: transform 1s ease-out;
    z-index: 1;
    align-self: center;
    text-align: center;
    justify-self: center;
}



.content-item img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1/1;
    z-index: 2;
    object-fit: cover;
}

  /* Fade Animation */
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 1.5s ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 1.5s ease-in;
  }

  .fade-enter h2 {
    transform: translateY(150%);
  }

  .fade-exit h2 {
    transform: translateY(150%);
  }
  
  .fade-enter .description {
    transform: translateY(-100%);
  }

  .fade-exit-active .description {
    transform: translateY(-100%);
  }


@media screen and (max-width: 900px) {
    .content-pane {
        justify-content: end;
        align-items: center;
        margin: 30vh 0;
    }

    .content-item {
        padding: 0 0;
        margin: 0 1rem;
    }

    .content-item h2 {
        font-size: 2rem;
        max-width: 70vw;
        min-width: 50vw;
        text-align: center;
        padding: 2rem 1rem;
    }


    .content-item .description {
        font-size: 1.1rem;
        margin: 0;
    }
}



