.timeline-item {
    position: absolute;
    display: flex;
    align-items: top;
    justify-content: center;
    width: 50%;
    margin: 2rem 0 2rem 0;
    opacity: 1;
    position: absolute;
    }
  
  .timeline-item.right {
    left: 50%;
  }
  
  .timeline-item.left {
    right: 50%;
    
  }

  .leftSlideIn {
    animation: slideInLeft 2s ease-out forwards;
  }

  .rightSlideIn {
    animation: slideInRight 2s ease-out forwards;
  }
  
  .timeline-item.left .timeline-icon {
    left: calc(100% - 2rem);
  }
  
  .timeline-item.right .timeline-icon {
    left: calc(-2rem);
  }
  
  .timeline-icon {
    top: 1rem;
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    background-color: #333;
    z-index: 2;
    border: solid 3px var(--secondary-light-color);
    color: #fff;
    box-shadow: -0.6rem 0.6rem 0.8rem rgba(0, 0, 0, 0.6);
  }

  .timeline-icon.animate.right {
    display: flex;
    animation: slideInRight 2s ease forwards;
  }

  .timeline-icon.animate.left {
    display: flex;
    animation: slideInLeft 2s ease forwards;
  }

  .timeline-info {
      display: none;
      position: relative;
      max-width: 50%;
      margin: 0 calc(10%);
  }

  .timeline-info.animate.left {
    display: block;
    animation: bounceLeft 2.2s ease-out forwards;
  }

  .timeline-info.animate.right {
    display: block;
    animation: bounceRight 2.2s ease-out forwards;
  }

  .timeline-content {
    position: relative;
    width: 100%;
    max-height: 10rem;
    overflow: hidden;
    transition: max-height 2.5s ease; 
    padding: 2rem;
    border-radius: 2rem;
    background-color: var(--light-color);
    color: var(--primary-color);
    line-height: 30px;
    box-shadow: -0.6rem 0.6rem 1.2rem rgba(0, 0, 0, 0.6);
  }


  @media (max-width: 850px) {
    .timeline-info {
      position: relative;
      max-width: 70%;
    }

    .timeline-content {
      padding: 1rem;
    }

    .timeline-content h2 {
      font-size: 1rem;
    }

    .timeline-content h4 {
      font-size: 0.8rem;
    }

    .timeline-content p {
      font-size: 0.7rem;
    }

    .timeline-info.right {
      margin-left: calc(20%);
      margin-right: calc(5%);
    }

    .timeline-info.left {
      margin-right: calc(20%);
      margin-left: calc(5%);

    }
  }
    

  @media (max-width: 1200px) {
    .timeline-content {
      max-height: 16rem;
    }
  }
  
  

.timeline-content:hover {
  max-height: 100vh;
}



.timeline-content p {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}


.timeline-content:hover p {
  opacity: 1;
}



  .timeline-connector {
    top: 2.8rem;
    display: none;
    position: absolute;
    width: 30%;
    height: 0.5rem;
    z-index: 0;
  }

  .timeline-connector.animate.right {
    display: block;
    left: 0;
    animation: connectorRight 2s ease forwards;
  }

  .timeline-connector.animate.left {
    display: block;
    right: 0;
    animation: connectorLeft 2s ease forwards;
  }


  .timeline-duration-container {
    top: 2rem;
    position: absolute;
    z-index: 1;
    opacity: 1;
    overflow: hidden;
  }

  .timeline-duration-container.right {
    left: 0;
    width: 3rem;
  }

  .timeline-duration-container.left {
    right: 0;
    width: 7px;
  }

  .timeline-duration {
    display: none;
    position: absolute;
    width: 7px;
    z-index: 1;
    opacity: 1;
  }

  .timeline-duration.aniamte.left {
    right: 0;
  }
  
  .timeline-duration.animate.right {
    left: 0;
  }

  .timeline-duration.animate {
    display: block;
    animation: slideInTop 4s ease forwards;
  }
  
  

  /* Animation for timeline items, in order */

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
    
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes bounceLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    20% {
      transform: translateX(-100%);
      opacity: 1;
    }
    70% {
      transform: translateX(20%);
      opacity: 1;
    }
    85% {
      transform: translateX(-10%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes bounceRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    30% {
      transform: translateX(100%);
      opacity: 1;
    }
    70% {
      transform: translateX(-20%);
      opacity: 1;
    }
    85% {
      transform: translateX(10%);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes connectorLeft {
    from {
      transform: translateX(-100%);
      opacity: 1;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes connectorRight {
    from {
      transform: translateX(100%);
      opacity: 1;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  @keyframes slideInTop {
    0% {
      opacity: 1;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }