.navbar {
    display: flex;
    gap: 2rem;
    background-color: var(--secondary-light-color);
    padding: 1rem 6rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    height: 4rem;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out, visibility 0.5s ease-out;
    transform: translateX(0);
    opacity: 1;
  }

  @media (max-width: 768px) {
    .nav-links {
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      position: absolute;
      top: 4rem;
      right: 0;
      background-color: var(--secondary-light-color);
      padding: 1rem 2rem;
      border-radius: 0 0 0 1rem;
      box-shadow: -0.3rem 0.3rem 0.2rem rgba(0, 0, 0, 0.3);
      transform: translateX(100%);
      transition: transform 0.5s ease-out, opacity 0.5s ease-out, visibility 0.5s 0.5s ease-out;
    }
  
    .nav-links.open {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      transition: transform 0.5s ease-out, opacity 0.5s ease-out, visibility 0s 0s ease-out;
    }
  }

  .nav-logo img {
    position: absolute;
    font-size: var(--font-size-title);
    font-weight: bold;
    margin-right: 2rem;
    top: 0.4rem;
    left: 2rem;
    width: 3.5rem;
    height: 3.5rem;
  }
  
  .nav-links li a, .nav-logo {
    color: var(--primary-color);
    transition: color 0.3s;
  }
  
  .nav-links li a:hover, .nav-logo:hover {
    /* color: var(--deep-purple-color); */
    color: var(--purple-link-color);
  }

  .nav-links li a.active, .nav-logo.active {
    /* color: var(--deep-purple-color); */
    color: var(--purple-link-color);
    /* underline active link */
    border-bottom: 2px solid var(--purple-link-color);
  }

  .hamburger {
    display: none;
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 2rem;
    z-index: 5;
    cursor: pointer;
    color: var(--secondary-color);
  }
  
  @media (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @keyframes navbarOpen {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }

  }

  @keyframes navbarClose {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }