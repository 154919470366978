.portfolio-section {
  background-color: var(--primary-color);
  top: 4rem;
  height: 100vh;
}

.portfolio-top {
  margin: 2rem 0;
  background-color: var(--secondary-color);
}

.carousel-wrapper {
  margin: 0 0rem 0rem 0rem;
  border-radius: 0rem 0rem 0 0;
  overflow: hidden;
}

.carousel-slide {
  position: relative;
  
  max-height: 50vh;
  
}

.carousel-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 50vh;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
}

.projects-grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: var(--primary-color);
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-radius: 3rem;
  overflow: hidden;
  border: solid;
  border-color: var( --secondary-light-color);
  background-color: var(--primary-color);
  padding-bottom: 1rem;
  margin: 4rem 8rem 0rem 8rem;
}

.project-card img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.project-card h3 {
  margin: 0;
}