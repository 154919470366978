.content-internal {
    width: 100%;
    height: 100%;
}

.input-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}


.panel-title {
    color: var(--primary-color);    
}

.keyword-text {
    color: var(--accent-color);
}

.contact-input {
    height: 4rem;
    font-size: 1.2rem;
    width: 17rem;
    transition: width 1s ease;
    animation: fadeIn 1s ease;
    border-radius: 2rem;
    border: none 4px var(--accent-color);
    padding: 0.5rem 2rem;
    box-shadow: -0.7rem 0.7rem 1.6rem rgba(0, 0, 0, 0.7);
}

.contact-input:focus {
    width: 90%;
    outline-width: 0;
}

@media (max-width: 768px) {
    .contact-input {
        width: 75%;
        font-size: 16px;
        padding: 0.2rem 1rem;
        height: 3rem;
    }

    .contact-input:focus {
        width: 100%;
    }
}

.progress-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;
    background-color: var(--accent-color);
    border: solid 2px var(--accent-color);
    color: var(--light-color);
    transition-duration: 0.4s;
    transition-property: background-color, color, border, opacity;
    border-radius: 50%;
    font-size: 4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 3;
    box-shadow: -0.8rem 0.8rem 1rem rgba(0, 0, 0, 0.6);
}

.progress-button.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
    pointer-events: auto;
  }
  
  .progress-button.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    pointer-events: none;
  }


.back-button {
    position: relative;
    float: left;
    left: -4.5rem;
    bottom: 50%;
}

.forward-button {
    position: relative;
    float: right;
    right: -4.5rem;
    bottom: 50%;
}


.progress-button:hover {
    background-color: var(--secondary-light-color);
    border: solid 2px var(--secondary-light-color);

    color: var(--accent-color);
  }

.progress-button:disabled {
    background-color: var(--primary-color);
    color: var(--secondary-light-color);
    border: solid 2px var(--primary-color);
    cursor: default;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
