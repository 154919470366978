.resume-page {
  background-color: var(--seconary-light-color);
  position: relative;
  padding-top: 4rem;
  }

  .section-title {
    margin-top: 1.5rem;
  }
  
  .download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    background-color: var(--accent-color);
    background: linear-gradient(to left, var(--accent-color) 50%, var(--secondary-light-color) 50%) right;
    background-size: 200% 100%;
    color: var(--light-color);
    transition: all 0.8s ease;
    border-radius: 50%;
    font-size: 2.2rem;
    cursor: pointer;
    margin: 2rem;
    z-index: 30;
    border: solid 2px var(--primary-color)
  }

  .download-button:hover {
    background-position: left;
    color: var(--accent-color);
  }
  