.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: var(--secondary-color);
    background-color: var(--secondary-color);
    overflow: hidden;
    background-image: linear-gradient(var(--secondary-color), 70%, var(--secondary-light-color));
  }
  

  .timeline-line {
    content: '';
    position: absolute;
    width: 4px;
    background-color: var(--secondary-light-color);
    left: 50%;
    z-index: 1;
    bottom: 740px;
  }

  .year-label {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left top;
    font-size: 65px; 
    color: var(--primary-color);
    left: calc(50%);
    white-space: nowrap;
  }
  
  
  
