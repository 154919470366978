/* Global styles */
:root {
  --primary-color: #4a4a4a;
  --secondary-color: #23153c;
  --accent-color: #0077b6;
  --light-color: #f0f0f0;
  /* a greyish verion of --light-color */
  --secondary-light-color: #d2d2d2;
  --deep-purple-color:#23153D;
  --purple-link-color: #43205a;
  --font-size-normal: 1rem;
  --font-size-title: 2rem;
  --font-size-subtitle: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: var(--secondary-color);
  color: var(--light-color);
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.section-title {
  font-size: var(--font-size-title);
  margin-bottom: 1rem;
}

.section-subtitle {
  font-size: var(--font-size-subtitle);
  margin-bottom: 0.5rem;
}

.faded-round-corner {
  /* Remove 'position: absolute' and 'top: 50vh' */
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  position: relative;
  color: var(--light-color);
  border-radius: 2rem;
  text-align: center;
}

