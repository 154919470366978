.about-me {
    display: flex;
    position: relative;
    top: 4rem;
    background-image: linear-gradient(var(--secondary-color), var(--secondary-light-color), 30%, var(--secondary-color), var(--secondary-light-color), var(--secondary-color));
  }
  

/* Sidebar */
.sidebar {
    position: fixed;
    width: 5rem;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
    padding-top: 4rem;
    background-color: var(--primary-color);
    height: 100vh;
    animation: fadeIn 1s ease-in forwards;
  }

.sidebar .sidebar-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
}
  
 

  .content {
    margin-left: 5rem;
    margin-bottom: 0;
    width: 100%;
    animation: fadeIn 1s ease-in forwards;
  }

  

  
  /* Responsive Design */
  @media screen and (max-width: 900px) {
    .sidebar {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      flex-direction: row;
      gap: 1rem;
      z-index: 3;
      padding-top: 1rem;
    }

    .content {
        margin-left: 0;
        margin-bottom: 5rem;
    }
  }
  