.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4rem;
  height: 110vh;
  min-height: 1000px;
  background-image: linear-gradient(var(--secondary-color), 90%, var(--secondary-light-color));
}

.contact-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in forwards;
}

.contact-header {
  padding: 1rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--secondary-light-color);
  border-radius: 2rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 20%;
  margin: 1rem 0;
  animation: fadeIn 1s ease-in forwards;
}

.rc-progress-line {
  box-shadow: -0.6rem 0.6rem 0.7rem rgba(0, 0, 0, 0.5);
}

.contact-content-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
  align-items: center;
  animation: fadeIn 1s ease-in forwards;
}


.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40vh;
  min-height: 400px;
  gap: 2rem;
  padding: 2rem;
  margin: 1rem 15%;
  border: 4px solid var(--secondary-light-color);
  background-color: var(--secondary-light-color);
  box-shadow: -1rem 1rem 1.4rem rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
}

@media (max-width: 768px) {
  .contact-content {
    margin: 1rem 10%;
  }
}




.social-media-section {
  display: flex;
  width: 100%;
  bottom: 0;
  z-index: 4;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 0 10%;
  animation: fadeIn 1s ease-in forwards;
}

.social-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-light-color);
  color: var(--accent-color);
  font-size: 3rem;
  padding: 1rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  transition-duration: 0.4s;
  transition-property: background-color, color, border;
  box-shadow: -1rem 1rem 1.4rem rgba(0, 0, 0, 0.6);
}

.social-icon:hover {
  color: var(--secondary-light-color);
  background-color: var(--accent-color);
}

