
.sidebar-item svg {
    font-size: 32px;
}

.sidebar-item .active svg{
    color: var(--accent-color);
    
  }

.sidebar-item svg:hover {
    color: var(--accent-color);
}
  