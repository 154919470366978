.landing-bg {
  /*  Static fix because of the navbar for the height of landing*/
  height: 96vh;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  top: 4rem;
}


.headshot-img {
  position: relative;
  width: 250px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  animation: headshotFadeIn 2.5s ease;
  margin-bottom: 3rem;
}

.typing-spacing {
  height: 16rem;
}


h1 {
  animation: fadeIn 2s;
  padding: 1rem;
  font-size: 60px;
  display: inline-block;
  position: relative;
  color: var(--light-color);
  text-align: center;
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }
}

@keyframes headshotFadeIn {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
